export default {
INVITATIONS_LIST: "Invitations list",
ADD_INVITATION: "Add an Invitation",
INVITATION_ADDED: "Invitation Added",
OWNER: "Owner",
EDIT_INVITATION: "Edit an Invitation",
INVITATION_UPDATED: "Invitation Updated",
DELETE_THIS_INVITATION: "Delete this Invitation?",
INVITATION_DELETED: "Invitation Deleted",
INVITATION_ACCEPTED: "Invitation Accepted",
INVITATION_APPROVED: "Invitation Approved",
ACCEPT_THIS_INVITATION: "Accept this Invitation?",
APPROVE_THIS_INVITATION: "Approve this Invitation?",
VIEW_INVITATION: "Invitation",
}