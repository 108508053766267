export default {
EVENTS_LIST: "Events list",
ADD_EVENTS: "Add an Event",
EVENTS_ADDED: "Event Added",
OWNER: "Owner",
EDIT_EVENTS: "Edit an Event",
EVENTS_UPDATED: "Event Updated",
DELETE_THIS_EVENTS: "Delete this Event?",
EVENTS_DELETED: "Event Deleted",
VIEW_EVENTS: "Event",
DATE_INVALIDE : "Date end must be great than start date",
TITLE: 'Title',
DATE_START: 'Start date',
DATE_END: 'End date',
RECURENT: 'Recurrent',
OCURENCE: 'Set recurrence',
FREQUENCE: 'Repeat every',
SELECT: 'Select',
ALL_DAYS: 'ALL days',
ALL_WEEKS: 'All weeks',
ALL_MONTH: 'All months',
THIS_EVENTS: 'This event',
ALL_EVENTS: 'All series',
EDIT: 'Edit',
DELETE_THIS_EVENT: 'Delete this event',
DELETE_ALL_EVENT: 'Delete the entire series',
ADD: 'Add',
COLOR: 'Color',
NOT_SELECTED: 'Oops... select a valid date',
SELECT_AUCTION: 'Select Auction'

}